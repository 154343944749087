.container {
  min-width: 85%;
}
.container-full {
  min-width: 100%;
  /* padding-top: 28px; */
  padding-bottom: 28px;
  /* padding-right: 0%;
  padding-left: 0%; */
  align-items: center;
  background-color: #ffffff;
}
.skeleton-container-full {
  min-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding-right: 0%;
  padding-left: 0%; */
  align-items: center;
  background-color: #ffffff;
}

.container-full,
a:hover {
  text-decoration: none;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 50px;
  border-radius: 10px;
  padding: 20px;
  height: 450px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
}

.button-pill {
  display: block;
  border-radius: 30px;
  background-color: #00c400;
  width: 45%;
  height: 50px;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: center;
  border: none;
  text-decoration: none;
}

.pl {
  padding-left: 16px;
}

.text-white {
  color: #ffffff;
}
.bg-orange {
  background-color: #f15b2a;
}
.horizontal-line {
  border-bottom: 1px solid #e5e5e5;
}
.row {
  margin: 0;
}
.section-header {
  padding-bottom: 25px;
}
.filters-div {
  position: sticky;
  top: 0px;
  height: 668px;
  overflow-y: scroll;
}

@media (max-height: 668px) {
  .filters-div {
    height: auto;
  }
}

@media (max-width: 576px) {
  .hidden-mobile {
    display: none;
  }
  .section-header {
    margin-right: 0px !important;
    margin-left: 19px !important;
    justify-content: flex-start;
  }
}

@media (min-width: 576px) {
  .hidden-desktop {
    display: none;
  }
  .section-header {
    margin-right: 40px !important;
    margin-left: 40px !important;
    justify-content: space-between;
  }
  .skeleton-section-title {
    width: 60%;
  }
}
.skeleton-section-title {
  width: 60%;
}
h2 {
  font-size: 18px;
  color: #383d42;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 24px;
  /* text-align: right; */
}
.white-text {
  color: #ffffff;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-30 {
  margin-bottom: 30px;
}
/* .pl-16 {
  padding-left: 16px !important;
} */
.page {
  min-height: 400px;
}
.main-nav img {
  width: 130px;
}
.btn-primary,
.btn-primary:hover {
  background-color: #00c400;
  border-color: #00c400;
}
.slick-dots li.slick-active button:before {
  opacity: 0.9;
  color: #00c400;
}
.slick-dots {
  position: relative;
  bottom: 10px;
}
.slick-next {
  right: 5px;
}
@media (min-width: 575px) {
  .container-full {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .skeleton-container-full {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pl {
    padding-left: 40px;
  }
}

@media (max-width: 575px) {
  .bannerIos {
    margin-top: 40px;
  }
}
.banner .slick-dots li button:before {
  font-size: 12px;
}

.MuiBottomNavigationAction-label,
.MuiBottomNavigationAction-label {
  font-size: 11px;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #00c400 !important;
}
.MuiLinearProgress-colorPrimary {
  background-color: rgb(197, 235, 197) !important;
}

.MuiBottomNavigation-root {
  height: 64px !important;
  background-color: #f2f2f2 !important;
  margin-top: 30px;
}
.MuiBottomNavigationAction-root.Mui-selected {
  text-decoration: none;
  color: #00c400 !important;
}
.MuiBottomNavigationAction-root {
  padding: 6px 4px 8px !important;
}
.MuiBottomNavigationAction-root {
  max-width: 100px;
}
.img-thumb-same-height {
  /* height: 180px; */
  width: 100%;
  object-fit: cover;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #00c400 !important;
}

.inalipa-btn-primary {
  border-radius: 28px;
  background-color: #00c400;
  color: white;
  text-align: center;
  padding: 20px 50px;
  border: none;
  width: 100%;
}
.inalipa-btn-disabled {
  background-color: rgba(239, 239, 239, 0.3) !important;
  color: rgba(16, 16, 16, 0.3) !important;
  border: none !important;
}

.inalipa-btn-primary a {
  font-size: 16px;
  font-weight: bold;
}

.btn-brown {
  background-color: #3c3f42 !important;
}
.line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 14px;
}
.product-label {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
}
.product-price {
  color: #757575;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 29px;
  text-align: center;
}
.btn-primary-small {
  border-radius: 25px;
  background-color: #00c400;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: center;
  border: none;
  display: inline;
  padding: 10px;
}
.MuiBottomNavigation-root {
  display: flex !important;
}

.navbar {
  padding: 0.5rem 0rem;
}

.color-primary {
  color: #00c400;
}
.bgOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000066;
  z-index: 1000000;
}
.w-input {
  padding: 10px;
}
.inalipa-btn-primary {
  background: #00c400;
  text-decoration: none;
  margin: 5px 10px;
}
a.inalipa-btn-primary {
  text-decoration: none;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #00c400 !important;
}

.circular-button {
  border-radius: 70px !important;
  padding: 12px 4px !important;
}
.MuiButtonGroup-grouped {
  min-width: 50px !important;
}
.circular-button:focus,
.circular-button:active {
  border: 0 !important;
}
button:focus {
  outline: none;
}
.close-icon {
  width: 16px;
  height: 16px;
  max-width: 100%;
  display: inline-block;
  border: 0;
  overflow: visible;
  background: none;
  position: relative;
  top: -8px;
}

.check-icon {
  width: 16px;
  height: 16px;
  max-width: 100%;
  display: inline-block;
  border: 0;
  overflow: visible;
  background: none;
  top: -3px;
  right: 4px;
}

.check-icon svg {
  color: #00c400;
}

.close-icon svg {
  color: #3f2100;
  vertical-align: middle;
  opacity: 0.7;
}
.close-icon:focus {
  border: none !important;
  outline: 0;
}
.error {
  color: #721c24;
}
.floating-cart {
  position: fixed !important;
  top: 10px !important;
  right: 15px !important;
  z-index: 100000000000000;
  background: #00c400;
  padding: 15px;
  border-radius: 100px;
}
.floating-cart-ios {
  position: fixed !important;
  top: 30px !important;
}
.floating-cart > div > div {
  background: #373d42;
}
.floating-cart-hidden {
  visibility: hidden;
}
.floating-filter h3,
.floating-sort h3 {
  margin-bottom: 0 !important;
}
.floating-sort {
  position: fixed !important;
  top: 171px !important;
  right: 15px !important;
  z-index: 1000;
  background: #00c400;
  padding: 3px 10px !important;
  border-radius: 100px;
}
.floating-sort-ios {
  position: fixed !important;
  top: 30px !important;
}
.floating-sort > div > div {
  background: #373d42;
}
.floating-filter {
  position: fixed !important;
  top: 171px !important;
  right: 115px !important;
  z-index: 1000;
  background: #00c400;
  padding: 3px 10px !important;
  border-radius: 100px;
}
.floating-filter svg {
  fill: white !important;
  color: white !important;
}
.floating-filter-ios {
  position: fixed !important;
  top: 130px !important;
}
.floating-filter > div > div {
  background: #373d42;
}

.MuiCircularProgress-svg {
  text-align: center;
  color: #00c400;
}
.appBgOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.3);
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(41, 47, 76, 0.5); /* Black background with opacity */
  z-index: 10000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.overlay-filter {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(41, 47, 76, 0.5); /* Black background with opacity */
  z-index: 100000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.discounted-price {
  color: #757575;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  font-weight: bold;
}

.active-price {
  color: #f15b2a;
  font-size: 20px !important;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 26px;
  text-align: center;
}

.toggle-filter {
  right: 0;
  cursor: pointer;
  z-index: 999999;
  color: #00c400;
}

ul.slick-dots {
  bottom: -10px;
}
.slick-dots li button:before {
  font-size: 12px;
}

.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child) {
  border-right-color: rgba(0, 0, 0, 0.23) !important;
}
.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}
.btn-default {
  border: none;
  width: 100%;
  cursor: pointer !important;
}
.bodyFade {
  overflow: hidden;
}

.overflowUnset {
  overflow: unset;
}
.err,
.sm-info {
  padding-top: 4px;
  font-size: 10px;
  color: #dd1e31;
  display: block;
  text-align: center;
}
.sm-info {
  color: #00c400;
}
h4.loading_heading_indicator {
  font-size: 14px !important;
  margin-top: 20px !important;
  margin-bottom: 65px !important;
  text-align: center !important;
  color: #00c400;
}
h4.loading_heading_indicator .MuiCircularProgress-root {
  width: 13px !important;
  height: 13px !important;
  margin-right: 5px !important;
}
h4.loading_location {
  font-size: 14px !important;
  margin-top: 20px !important;
  text-align: center !important;
  color: #00c400;
}
h4.loading_location .MuiCircularProgress-root {
  width: 13px !important;
  height: 13px !important;
  margin-right: 5px !important;
}
.btn-copy {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px;
  width: 100%;
}
.alert-copy {
  color: #00c400;
  font-size: 13px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}
a[disabled='disabled'] {
  pointer-events: none;
}
.react-datepicker__input-container {
  padding-bottom: 10px;
}

.swal2-icon.swal2-info {
  border-color: #00c400;
  color: #00c400;
}

.swal2-container.swal2-center {
  z-index: 10000;
}

/* div[class^="MuiBox-root MuiBox-root-"] {
    padding: 24px 5px !important;
} */

.MuiTab-textColorPrimary.Mui-selected {
  color: #00c400 !important;
}

span.MuiTabs-indicator {
  background-color: #00c400 !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #00c400 !important;
}

.carousel .control-dots .dot {
  background: #00c400 !important;
}

.carousel .carousel-status {
  font-size: 12px !important;
  color: #767676 !important;
}

@media (min-width: 576px) {
  .MuiBottomNavigation-root {
    display: none !important;
  }
  .bodyFade {
    overflow: unset;
  }
  .overflowUnset {
    overflow: unset;
  }

  .line-2 {
    line-height: 20px;
  }
  /*.active-price {*/
  /*  font-size: 15px !important;*/
  /*}*/
}

@media (min-width: 320px) {
  .main-nav img {
    width: 120px;
  }
}

.react-horizontal-scrolling-menu--scroll-container {
  padding-bottom: 20px;
}

/* show scroll bar when user hovers on container-full */
/* .container-full:hover{
  overflow-x: scroll;
  scrollbar-track-color: #00c400;
} */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 42%;
  /* display: block; */
  width: 55px;
  height: 55px;
  padding: 0;
  z-index: 1000;
  transform: translate(0, -50%);
  cursor: pointer;
  /* color: transparent; */
  border: none;
  outline: none;
  background: transparent;

  /* color: transparent; */
}

.slick-next {
  right: 35px;
}

.slick-prev {
  left: 35px;
}

.slick-prev:before,
.slick-next:before {
  content: none;
}

.slick-arrow {
  opacity: 0;
  transition-timing-function: ease;
  transition-duration: 0.7s;
}

.container-full:hover .slick-arrow {
  opacity: 1;
  transition-timing-function: ease;
  transition-duration: 0.7s;
}

/* .slick-track{
  display: block;
} */

.slick {
  display: block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slick::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slick {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.arrow-container:hover {
  background-color: #00c400;
  box-shadow: 0px 0px 6px 4px rgb(0 0 0 / 30%);
  transition-timing-function: ease;
  transition-duration: 0.5s;
}

.arrow-container {
  background-color: rgb(0, 0, 0, 0.45);
  transform-origin: bottom;
  animation: stretch4 1s linear infinite;
  -moz-animation: stretch4 1s linear infinite;
  -webkit-animation: stretch4 1s linear infinite;
}

.arrow-arrow {
  fill: #ffffff;
}

/* show scroll bar on hover of container */
.body {
  overflow: scroll;
}
