@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.NotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px 200px 0px;
}

.NotFound svg {
  justify-content: center;
  width: 100%;
  height: auto;
  max-height: 50vh;
}

.NotFound h1 {
  margin: 20px 0px !important;
}

.NotFound p {
  color: rgba(0, 0, 0, 0.6);
}

.NotFound a,
.NotFound a:hover {
  padding: 10px 50px;
  background: rgb(0, 196, 0);
  border: 1px solid #cccc;
  border-radius: 50px !important;
  color: white;
  border: none;
  margin-bottom: 100px;
  font-weight: bold;
}
