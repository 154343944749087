.OrderSummary {
  margin-bottom: 15px;
}

.OrderSummary_cartItems .MuiAccordionDetails-root {
  flex-direction: column !important;
  margin-bottom: 15px;
}
.OrderSummary_cartItems_item {
  display: flex;
  padding: 20px;
  align-items: flex-start;
}
.OrderSummary_cartItems_item img {
  max-width: 100px;
}
.OrderSummary_cartItems_item_description {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
}
.OrderSummary_cartItems_item_description_name h2 {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
  margin-left: 0px !important;
  margin-bottom: 0px;
}
.OrderSummary_cartItems_item_description_price {
  display: flex;
  align-items: center;
}
.OrderSummary_cartItems_item_description_price_quantity h4 {
  color: #333 !important;
  font-size: 14px !important;
}
.OrderSummary_cartItems_item_description_price_total h3 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 21px;
  text-align: right;
  margin-bottom: 0px !important;
}
.OrderSummary_cartItems_item_description_price_total {
  margin-left: auto;
  padding-right: 15px;
}

.OrderSummary_footer {
  background-color: #f5f5f5;
  padding: 20px;
}
.OrderSummary_footer_title {
  color: #757575;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 21px;
}
.OrderSummary_footer_value {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 21px;
  text-align: right;
}
.OrderSummary_separator {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid #e6e6e6;
  opacity: 0.6;
}

.MuiAccordionDetails-root {
  padding: 0px !important;
}
