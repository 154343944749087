.autocomplete-input{
    width: 100% !important;
    height: 50px !important;
    margin-bottom: 30px !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-color: rgba(157, 117, 68, 0.12) !important;
}

.current-location-picker {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(157, 117, 68, 0.12);
    padding: 10px;
    margin: 10px 0px;
  }
  .current-location-picker label {
    font-family: 'DM Sans', sans-serif;
    color: #000000;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .current-location-picker  input{
      cursor: pointer;
  }