.filterForm_wrapper {
  display: none;
}
select {
  float: right;
}
h2 {
  line-height: normal !important;
  margin-top: 10px !important;
}
.sortBy {
  display: none;
}
h4.loading_heading_indicator {
  font-size: 14px !important;
  margin-top: 20px;
  margin-bottom: 65px;
  text-align: center;
}
@media (min-width: 768px) {
  .filterForm_wrapper {
    display: block;
  }
  .sortBy {
    display: block;
  }
}
