@media (max-width: 576px) {
  .appLayout .children {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
  }
  .appLayout__home {
    margin-top: 77px;
  }
  .appLayout__home__ios {
    margin-top: 90px;
  }
}
