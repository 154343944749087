.ContactComponent {
}
.ContactComponent h2 {
  font-size: 15px;
  padding: 10px;
  color: white;
  text-align: right;
}
.ContactComponent .MuiIconButton-root {
  background: #00c400 !important;
  float: right;
  margin-right: 30px;
}
.ContactComponent__itemRight .MuiIconButton-root {
  background: #fff !important;
}

.ContactComponent__itemRight .MuiSvgIcon-root {
  color: #00c400 !important;
}

.ContactComponent__item {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.ContactComponent__itemLeft {
  border: 0.5px solid rgba(255, 255, 255, 0.12);
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12), 0 12px 12px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.ContactComponent__item h3 {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
}
