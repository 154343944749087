.pastOrders {
  /*margin-top: 20px;*/
}

h2 {
  font-size: 16px;
  color: #0f1111;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
}
h3 {
  font-size: 16px;
  padding: 5px;
}
h4 {
  font-size: 12px !important;
  line-height: 18px;
  font-weight: 600;
  color: rgb(52, 53, 56);
}
.pastOrders_row {
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #ccc;
  text-align: center;
}
.pastOrders_row_items {
  display: flex;
  flex-wrap: wrap;
}
.pastOrders_row_item {
  flex: 1 0 25%;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
  max-width: 200px;
}
.pastOrders_image {
  max-width: 150px;
  margin: 0 auto;
}
.pastOrders_action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
}
.pastOrders_action_btn {
  max-width: 300px;
}
