@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  /* margin-bottom: constant(safe-area-inset-top); */
  /* margin-bottom: env(safe-area-inset-top); */
  /* padding-bottom: constant(safe-area-inset-top); iOS 11.0 */
  /* padding-bottom: env(safe-area-inset-top); iOS 11.2 */
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
*:focus {
  outline: none;
}
a {
  text-decoration: none;
}
button {
  background: none;
}
#fc_frame,
#fc_frame.fc-widget-normal {
  bottom: 60px !important;
  z-index: 10000 !important;
}
#fc_frame.fc-mobile-view.h-open-container.fc-open,
#fc_frame.fc-widget-fullscreen.fc-widget-normal,
#fc_frame.fc-widget-fullscreen.fc-widget-small {
  padding-top: env(safe-area-inset-top);
}
.mb-80 {
  margin-bottom: 80px !important;
}

/* * {
  border: 1px solid #f00 !important;
} */
