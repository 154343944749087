.appliedFilters_row {
  display: flex;
}
.appliedFilters_row span {
  text-decoration: none;
  font-size: 14px;
  text-align: left;
  margin: 0;
  font-weight: 600;
}
.appliedFilters_row button {
  margin-left: auto;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: underline;
  float: right;
  margin-right: 15px;
  border: 0;
  padding-right: 0;
  padding-left: 0;
  background: #fff;
}
.appliedFilters_content {
  width: 100%;
  display: table;
  table-layout: fixed;
  margin-top: 6px;
}
.appliedFilters_content button {
  display: inline-block;
  font-size: 14px;
  color: #00c400;
  vertical-align: middle;
  padding: 0 30px 0 0;
  max-width: 100%;
  text-align: left;
  border: none;
  margin: 2px 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
}
.appliedFilters_content svg {
  font-size: 14px;
}

.hide {
    display: none !important;
    visibility: hidden;
}
