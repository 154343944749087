.skeleton-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%;
    height: 100%; */
}
.skeleton-category-title {
  padding-top: 10px;
}
.skeleton-category-image {
  /* margin: 0px 10px 0 10px; */
  width: 100px !important;
  height: 100px !important;
}

@media (max-width: 480px) {
  .skeleton-category-image {
    width: 60px !important;
    height: 60px !important;
  }
}
/* @media(max-width: 820px){
    .skeleton-category-image{
        width: 150px !important;
        height: 150px !important;
    }
} */

.Vertical-Card {
  display: inline-block;
  margin: 0.5em;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  width: 60px;
  height: 60px;
  /* user-select: none; */
}

.Shop-Card {
  display: inline-block;
  margin: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 180px;
  height: 180px;
  /* user-select: none; */
}

.Boxed-Card {
  display: inline-block;
  margin: 0.5em;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  background-color: #ffffff;
  width: 150px;
  height: 150px;
  /* user-select: none; */
}

.circle {
  border-radius: 50%;
}

.square {
  border-radius: 20%;
}

.box {
  border-radius: 10%;
}

.store {
  border-radius: 5px;
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.slider-item {
  padding: 0px 9.5px 0 9.5px;
}

@media (min-width: 575px) {
  .Boxed-Card {
    width: 240px;
    height: 240px;
  }
  .Vertical-Card {
    width: 100px;
    height: 100px;
  }
  .slider-item {
    padding: 0px 20px 0 20px;
  }
  .vertical-circle-scroll-card-skeleton {
    width: 240px;
    height: 240px;

    background-color: #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    padding: 0px 20px 0 20px;
  }
}
